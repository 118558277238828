export default class RefParent {
    private elem: HTMLElement;

    constructor(elem: HTMLElement) {
        this.elem = elem;
    }

    public ref<K extends keyof HTMLElementTagNameMap>(ref: string, tag: K ): HTMLElementTagNameMap[K] | null {
        return this.elem.querySelector(tag + '[data-ref="' + ref + '"]');
    }

    public div(ref: string): HTMLDivElement | null {
        return this.elem.querySelector( 'div[data-ref="' + ref + '"]');
    }

}