import type Splide from "@splidejs/splide";

interface SplideImport {
    Splide: typeof Splide,
}


export default class SplideLoader {

    /**
     * 0 - not loaded
     * 1 - loading
     * 2 - loaded
     * @private
     */
    private static status = 0;

    private static promise: Promise<SplideImport>;

    private static instance: SplideImport;

    public static load(): Promise<SplideImport>{
        if(SplideLoader.status == 0) {
            SplideLoader.status = 1;

            SplideLoader.promise = Promise.all([
                import(/* webpackChunkName: "splide-js" */ '@splidejs/splide'),
            ]).then(v => {

                let core = v[0];

                let exp = {
                    Splide: ((core as any).default)
                } as unknown as SplideImport;

                SplideLoader.instance = exp;
                SplideLoader.status = 2;

                return exp;

            });

            return SplideLoader.promise;
        }else if(SplideLoader.status == 1){
            return SplideLoader.promise;
        }else {
            return Promise.resolve(SplideLoader.instance);
        }
    }

}