import Coords from 'app/lib/interelcom/photofind/crop/support/Coords';

export default class Blend {
    private parent: HTMLElement;
    private elem: HTMLElement;
    private width: number;
    private height: number;

    constructor(parent: HTMLElement, width: number, height: number, nw: Coords, se: Coords) {
        this.parent = parent;
        this.width = width;
        this.height = height;

        this.elem = document.createElement('div');
        this.elem.style.position = 'absolute';
        //this.elem.style.pointerEvents = 'none';
        this.elem.style.backgroundColor = 'rgba(0,0,0,0.4)';

        this.elem.style.left = nw.x + 'px';
        this.elem.style.right = (this.width - se.x) + 'px';

        this.elem.style.top = nw.y + 'px';
        this.elem.style.bottom = (this.height - se.y) + 'px';

        this.parent.appendChild(this.elem);
    }

    public clean(){
        this.elem.remove();
    }

    public updateLeft(x: number){
        this.elem.style.left = x + 'px';
    }

    public updateRight(x: number){
        this.elem.style.right = (this.width - x) + 'px';
    }

    public updateTop(y: number){
        this.elem.style.top = y + 'px';
    }

    public updateBottom(y: number){
        this.elem.style.bottom = (this.height - y) + 'px';
    }
}