export default class TFJSLoader {

    /**
     * 0 - not loaded
     * 1 - loading
     * 2 - loaded
     * @private
     */
    private static status = 0;

    private static promise: Promise<any>;

    private static instance: any;

    public static load(): Promise<any>{
        if(TFJSLoader.status == 0) {
            TFJSLoader.status = 1;
            TFJSLoader.promise = (import(/* webpackChunkName: "tfjs" */ '@tensorflow/tfjs'))
                .then(dm => {


                    console.log("dm.default", dm.default);

                    let dz_:any = (dm as any);

                    console.log("dm", dm);

                    TFJSLoader.instance = dz_;
                    TFJSLoader.status = 2;
                    return dz_;
                });
            return TFJSLoader.promise;
        }else if(TFJSLoader.status == 1){
            return TFJSLoader.promise;
        }else {
            return Promise.resolve(TFJSLoader.instance);
        }
    }

}