import DceHTMLElement from "../../../dce-engine/lib/types/DceHTMLElement";
import DceLiveEventDetails from "./types/DceLiveEventDetails";
import DceElementLiveEventListener from "./DceElementLiveEventListener";

export default class DceElementWithLiveEvents {

    private element: DceHTMLElement;

    constructor(element: DceHTMLElement) {
        this.element = element;

        element.addEventListener('dceLiveEvent', ((e: CustomEvent<DceLiveEventDetails>) => {
            this.onDceLiveEvent(e);
        }) as EventListener);

        this.addLiveEventListener('click');
        this.addLiveEventListener('change');
    }

    protected onDceLiveEvent(e: CustomEvent<DceLiveEventDetails>){
        let ext = this.element.ext;

        e.detail.actions.forEach(action => {
            if(ext.hasComponent(action.component)){
                let comp:any = ext.getComponent(action.component) as any;
                if(typeof comp[action.method] === 'function'){
                    comp[action.method](e.detail.originalEvent, e.detail.element);
                }
            }
        });

    }


    protected addLiveEventListener(eventType:string){
        this.element.addEventListener(eventType, new DceElementLiveEventListener(this.element, eventType), true);
    }
}