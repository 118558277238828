import MovingPoint from "app/lib/interelcom/photofind/crop/MovingPoint";
import Blend from "app/lib/interelcom/photofind/crop/Blend";
import Coords, {max, min, rectDims} from "app/lib/interelcom/photofind/crop/support/Coords";
import MoveArea from "app/lib/interelcom/photofind/crop/MoveArea";
import RefParent from "app/lib/dom/RefParent";
import Crop from "app/lib/interelcom/photofind/crop/Crop";
import {MobilenetConf, TopPrediction} from "app/lib/interelcom/tfjsutils/MobilenetPrediction";
import {DceAjax} from "dce-ajax";

export default class PhotoFindResult {
    private elem: HTMLElement;
    private root: RefParent;

    private canvasContainer: HTMLElement;
    private tableContainer: HTMLElement;

    private backButton: HTMLButtonElement;

    private uploadClone: HTMLCanvasElement;

    private resultGuid: String;

    private backCallback: () => void;
    private texts: { [p: string]: string };
    private showDebug: boolean;

    constructor(elem: HTMLElement, texts: { [p: string]: string }, showDebug: boolean) {
        this.elem = elem;
        this.texts = texts;
        this.showDebug = showDebug;
        this.root = new RefParent(elem);

        this.canvasContainer = this.root.div('result-canvas');
        this.tableContainer = this.root.div('result-table');

        this.backButton = this.root.ref('result-back', 'button');

        this.backButton.addEventListener('click', e => {
            this.doBack();
        });
    }

    public setBackCallback(callback: () => void) {
        this.backCallback = callback;
    }

    private doBack() {
        if (null != this.backCallback) {
            this.backCallback();
        }
    }

    public hide() {
        this.elem.style.display = 'none';
    }

    public show() {
        this.elem.style.display = 'block';
    }

    public setCanvas(canvas: HTMLCanvasElement, modelWidth: number, modelHeight: number) {

        this.canvasContainer.innerHTML = '';

        if (this.showDebug) {
            canvas.style.maxWidth = '100%';
            this.canvasContainer.append(canvas);
        }

        let clone: HTMLCanvasElement = document.createElement('canvas');
        clone.width = 224;
        clone.height = 224;
        clone.getContext('2d').drawImage(
            canvas, 0, 0, canvas.width, canvas.height,
            0, 0, modelWidth, modelHeight
        );

        this.uploadClone = clone;

        this.canvasContainer.append(clone);

    }

    public setLoading() {
        this.tableContainer.innerHTML = 'Wyszukiwanie...';
    }

    public setPredictions(modelConf: MobilenetConf, preds: TopPrediction[], uploadUrl: string) {

        this.uploadClone.toBlob(blob => {
            let file = new File([blob], 'f.png', {type: 'image/png'})

            console.log(file)

            let formData = new FormData();
            formData.set('model', modelConf.label);
            formData.set('image', file)
            formData.set('predictions', JSON.stringify(preds))

            //get categories
            DceAjax.getInstance()
                .post(uploadUrl, formData)
                .then(response => {

                    this.tableContainer.innerHTML = '';

                    if (response.hasResponse()) {
                        let resp = document.createElement("div");
                        resp.innerHTML = this.replateTexts(response.response);
                        this.tableContainer.append(resp);
                    }

                    if (this.showDebug) {
                        let table = document.createElement('table');
                        table.append(
                            ...preds
                                .filter(value => {
                                    return value.probability > 0.0001;
                                })
                                .map(pred => {
                                    let tr = document.createElement('tr');

                                    let classLabel = document.createElement('td');
                                    classLabel.innerText = pred.className;

                                    let prob = Number(pred.probability * 100).toFixed(2);

                                    let popab = document.createElement('td');
                                    popab.innerHTML = prob + "%" + "<br/><small style='font-size: 2px;'>" + pred.probability + "</small>"

                                    tr.append(classLabel, popab);

                                    return tr;
                                })
                        );

                        this.tableContainer.append(table);
                    }

                })

        }, 'image/png');


    }

    private replateTexts(content: string): string {
        console.log(content);
        for (const textsKey in this.texts) {
            content = content.replace("\%" + textsKey + "\%", this.texts[textsKey]);
            console.log(content, textsKey);
        }
        return content;
    }

}