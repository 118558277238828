import DceEngineEventDetails from "./DceEngineEventDetails";
import DceHTMLElement from "../../types/DceHTMLElement";

export default class DceEngineEvents {

    public static INIT_EVENT = 'init';
    public static REMOVE_EVENT = 'remove';
    public static SUBCOMP_INIT_EVENT = 'subcompInit';
    public static SUBCOMP_REMOVE_EVENT = 'subcompRemove';

    public static initEvent(component: string, elem: DceHTMLElement): CustomEvent<DceEngineEventDetails> {
        return new CustomEvent<DceEngineEventDetails>("dceEvent",
            {
                detail: {
                    type: this.INIT_EVENT,
                    component: component,
                    element: elem
                }
            }
        );
    }

    public static subComponentInit(component: string, elem: DceHTMLElement): CustomEvent<DceEngineEventDetails> {
        return new CustomEvent<DceEngineEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_INIT_EVENT,
                    component: component,
                    element: elem
                }
            }
        );
    }

    public static subComponentRemove(component: string, elem: DceHTMLElement): CustomEvent<DceEngineEventDetails> {
        return new CustomEvent<DceEngineEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_REMOVE_EVENT,
                    component: component,
                    element: elem
                }
            }
        );
    }

    public static removeEvent(component: string, elem: DceHTMLElement): CustomEvent<DceEngineEventDetails> {
        return new CustomEvent<DceEngineEventDetails>("dceEvent",
            {
                detail: {
                    type: this.REMOVE_EVENT,
                    component: component,
                    element: elem
                }
            }
        );
    }

}