export default class Loading {
    private elem: HTMLElement;

    private label: HTMLDivElement;

    constructor(elem: HTMLElement) {
        this.elem = elem;
        this.label = elem.querySelector('div[data-ref="loading-label"]');
    }

    public hide(){
        this.elem.style.display = 'none';
    }

    public setLabel(label: string){
        this.label.innerText = label;
    }

    public setError(label: string, reason: string){
        this.label.innerText = label;

        let pre = document.createElement("pre");
        pre.style.whiteSpace = 'pre';
        pre.innerHTML = reason;
        this.label.append(pre);

    }
}