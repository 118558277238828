import {DceAjax} from "dce-ajax";
import DceAjaxOpenModalAction from "app/lib/ajax/DceAjaxOpenModalAction";
import DceAjaxReplaceElementAction from "app/lib/ajax/DceAjaxReplaceElementAction";
import DceAjaxRemoveElementAction from "app/lib/ajax/DceAjaxRemoveElementAction";
import DceAjaxSetLocalStorageAction from "app/lib/ajax/DceAjaxSetLocalStorageAction";

export {default as ShowMore} from "./components/ShowMore"
export {default as BodyClassToggle} from "./components/BodyClassToggle"
export {default as Modal} from "./components/Modal";
export {default as OpenModal} from "./components/OpenModal"
export {default as AjaxForm} from "./components/AjaxForm"
export {default as Share} from "./components/Share"
export {default as SearchAutocomplete} from "./components/SearchAutocomplete"
export {default as MobileMenu} from "./components/MobileMenu"
export {default as ShowNext} from "./components/ShowNext"
export {default as Filters} from "./components/Filters"
export {default as AjaxBlock} from "./components/AjaxBlock"
export {default as CategoriesMenu} from "./components/CategoriesMenu"
export {default as ManuScreens} from "./components/MenuScreens"
export {default as AjaxCallLink} from "./components/AjaxCallLink"
export {default as PhotoFind} from "./interelcom/PhotoFind"
export {default as ImagesGallery} from "./components/ImagesGallery"
export {default as LocalStorageSyncBlock} from "./components/LocalStorageSyncBlock"
export {default as QuantityInput} from "./components/QuantityInput"

DceAjax.getInstance().registerActionHandler('open_modal', new DceAjaxOpenModalAction());
DceAjax.getInstance().registerActionHandler('replace_element', new DceAjaxReplaceElementAction());
DceAjax.getInstance().registerActionHandler('remove_element', new DceAjaxRemoveElementAction());
DceAjax.getInstance().registerActionHandler('set_local_storage', new DceAjaxSetLocalStorageAction());