import {DceAjaxAction} from "dce-ajax";
import {OpenModal} from "app";
import {createDomElementFromString} from "app/lib/utils/createDomElementFromString";

interface ReplaceElementData {
    elements: {
        selector: string
    }[];
}

export default class DceAjaxRemoveElementAction implements DceAjaxAction {

    execute(data: any): void {
        let arr = data as ReplaceElementData;

        arr.elements.forEach(d => {
            document.querySelectorAll(d.selector).forEach(elem => {
                elem.remove();
            });
        });

    }
    getPriority(): number {
        return 0;
    }

}