import {DceComponent, DceHTMLElement} from "dce-engine";
import {DceAjax,  DceAjaxResponse} from "dce-ajax";
import Dce from "dce-engine/lib/decorators/Dce";

interface QuantityInputProps {
    min: number,
    step: number,
    submitOnChange: boolean;
}

@Dce("QuantityInput")
export default class QuantityInput extends DceComponent<QuantityInputProps>{

    private quantityInput: HTMLInputElement;
    private form: HTMLFormElement;

    private inputTimeout: any = null;

    constructor(elem: DceHTMLElement, props: QuantityInputProps) {
        super(elem, props);

        this.quantityInput = elem.querySelector('input[name=quantity]')

        this.form = this.quantityInput.closest('form');

        if(props.submitOnChange){
            this.quantityInput.addEventListener('change', e =>{
                this.form.dispatchEvent(new Event('submit'));
            });
        }

        this.quantityInput.addEventListener('input', e=> {

            console.log("input")

            if(null != this.inputTimeout){
                clearTimeout(this.inputTimeout);
            }

            this.inputTimeout = setTimeout(() => {

                let quant = parseInt(this.quantityInput.value);

                if(quant != null && !isNaN(quant)){

                    let mod = (quant % this.props.step)

                    console.log(mod);

                    if(mod > 0){
                        let newquant = quant - mod + this.props.step;
                        this.quantityInput.value = newquant + "";
                    }

                }


            }, 300);


        });

    }

    public decrementQuantity(e: Event){
        this.changeQuantity(v => v-this.props.step);
    }

    public incrementQuantity(e: Event){
        this.changeQuantity(v => v+ this.props.step);
    }

    private changeQuantity(callback: (i:number) => number){

        let quant = parseInt(this.quantityInput.value);
        quant = callback(quant);
        if(quant == null || quant <= 0 || isNaN(quant)){
            quant = this.props.min;
        }

        this.quantityInput.value = quant + "";
        this.quantityInput.dispatchEvent(new Event('change', { bubbles: true }))
    }


    defaultProps(): Partial<QuantityInputProps> {
        return {
            step: 1,
            submitOnChange: false
        }
    }
}