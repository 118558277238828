import {DceAjaxAction} from "../../index";
import {data} from "jquery";

/**
 * redirect action
 */
export default class DceAjaxNewTabAction implements DceAjaxAction {
    execute(data: any): void {
        window.open(data as string, "_blank");
    }

    getPriority(): number {
        return 0;
    }

}