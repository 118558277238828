import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";
import {Modal} from "app/lib/index";
import {DceAjax} from "dce-ajax";
import {CancelTokenSource} from "axios";
import {localResponseNormalization} from "@tensorflow/tfjs";
import {createDomElementFromString} from "app/lib/utils/createDomElementFromString";

interface LocalStorageSyncBlockProps {
    //klucz kóry obserwujemy
    key: string,

    //aktualna wartosc, jesli ulegnie zmiany robimy refresh
    value: string,

    //adres url do odswieżenia bloku przy zmianie wartości
    url: string
}

@Dce("localStorageSyncBlock")
export default class LocalStorageSyncBlock extends DceComponent<LocalStorageSyncBlockProps> {

    private content: HTMLElement;

    private requestCancelTokenSource: CancelTokenSource;

    constructor(elem: DceHTMLElement, props: LocalStorageSyncBlockProps) {
        super(elem, props);

        console.log("LOCAL STORAGE SYNC");

        window.addEventListener('storage', e => {

            let newValue = localStorage.getItem(this.props.key);

            if(newValue == this.props.value){
                return;
            }

            if(null != this.requestCancelTokenSource){
                this.requestCancelTokenSource.cancel("Next change");
                this.requestCancelTokenSource = null;
            }

            console.log("UPDATE VALUE TO: " + newValue);

            this.requestCancelTokenSource = DceAjax.cancelTokenSource();

            DceAjax.getInstance().get(this.props.url, {
                cancelToken: this.requestCancelTokenSource.token
            }).then(response => {
                this.requestCancelTokenSource = null;

                if(response.isValid() && response.hasResponse()){
                    this.elem.replaceWith(
                        createDomElementFromString(response.response)
                    );
                }

            });

        });

    }

}