import {DceAjaxAction} from "dce-ajax";
import {OpenModal} from "app";
import {createDomElementFromString} from "app/lib/utils/createDomElementFromString";

interface SetLocalStorageData {
    values: {
        key: string,
        value: string
    }[];
}

export default class DceAjaxSetLocalStorageAction implements DceAjaxAction {

    execute(data: any): void {
        let arr = data as SetLocalStorageData;

        arr.values.forEach(kv => {
            localStorage.setItem(kv.key, kv.value);
        });

    }

    getPriority(): number {
        return 1000;
    }

}