import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface ModalProps {
    removeOnClose: boolean
}

@Dce("modal")
export default class Modal extends DceComponent<ModalProps> {

    private content: HTMLElement;

    constructor(elem: DceHTMLElement, props: ModalProps) {
        super(elem, props);

        this.elem.querySelectorAll('.close').forEach(elem => {
            elem.addEventListener('click', e => this.close());
        })

        this.elem.addEventListener('click', e => {
            if(e.target == this.elem) {
                this.close();
            }
        })

        if(location.hash && location.hash.startsWith("#open-modal")){

            let openhash = location.hash.split(":");

            if(openhash.length > 1 && openhash[1] == this.elem.id){
                this.open();
                location.hash = "";
            }
        }

        if(this.elem.getAttribute('data-open') == "1"){
            this.open();
        }
    }

    private dynamicElements: Element[] = [];

    public open() {

        let dialog = this.elem.querySelector('div[data-role="dialog"]');

        this.elem.querySelectorAll('script[data-role="content"]').forEach(s => {
            let tmp = document.createElement('div');
            tmp.innerHTML = s.innerHTML;
            this.dynamicElements.push(tmp.firstElementChild);
            dialog.append(tmp.firstElementChild);
        });

        document.body.style.paddingRight = this.scrollbarWidth() + 'px';
        document.body.classList.add('modal-opened');
        this.elem.classList.add('opened');
    }

    public close(){
        this.elem.classList.remove('opened');
        document.body.classList.remove('modal-opened');
        document.body.style.paddingRight = null;

        this.dynamicElements.forEach(de => {
            de.remove();
        })
        this.dynamicElements = [];

        if(this.props.removeOnClose){
            setTimeout(() => {
                this.elem.remove();
            }, 200);
        }
    }

    private scrollbarWidth(): number {
        const documentWidth = document.documentElement.clientWidth;

        //console.log(documentWidth, window.innerWidth);

        return Math.abs(window.innerWidth - documentWidth);
    }


    defaultProps(): Partial<ModalProps> {
        return {
            removeOnClose: false
        }
    }
}