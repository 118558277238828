import {DceAjaxAction} from "dce-ajax";
import {Modal, OpenModal} from "app";
import {createDomElementFromString} from "app/lib/utils/createDomElementFromString";
import {DceHTMLElement} from "dce-engine";

export default class DceAjaxOpenModalAction implements DceAjaxAction {

    execute(data: any): void {
        let modalDiv = createDomElementFromString(data);

        modalDiv.setAttribute('data-open', "1")

        document.body.append(modalDiv);
        //
        // console.log(modalDiv);
        //
        // let ext = (modalDiv as DceHTMLElement).ext
        //
        // console.log("EXT ", ext)
        //
        // if(ext){
        //     let modalExt = ext.getComponent('modal');
        //
        //     if(modalExt){
        //         (modalExt as Modal).open();
        //     }
        // }
    }

    getPriority(): number {
        return 0;
    }

}