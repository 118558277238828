import Coords, {add, diff} from "app/lib/interelcom/photofind/crop/support/Coords";
import MovingPoint from "app/lib/interelcom/photofind/crop/MovingPoint";

interface PointStartCoords{
    point: MovingPoint,
    startCoords: Coords
}

export default class MoveArea {
    private elem: HTMLElement;

    private parentRect: DOMRect;

    private started: boolean = false;
    private startCoords: Coords;
    private startInnerCoords: Coords;

    private listeners: {
        mousedown: (e: MouseEvent) => void,
        mousemove: (e: MouseEvent) => void,

        touchstart: (e: TouchEvent) => void,
        touchmove: (e: TouchEvent) => void,

        end: (e: MouseEvent|TouchEvent) => void,
    }

    private points: MovingPoint[];
    private startPointCoords: PointStartCoords[];

    private movedCallback: (diff: Coords) => void;

    constructor(elem: HTMLElement, points: MovingPoint[]) {
        this.elem = elem;
        this.points = points;

        this.listeners = {
            mousedown: (e) => {
                if (this.elem != e.target) {
                    return;
                }

                this.parentRect = this.elem.getBoundingClientRect();

                this.startPointCoords = this.points.map(v => {
                    return {
                        point: v,
                        startCoords: v.getPos()
                    }
                });

                this.started = true;
                this.startCoords = this.coords(e);
                this.startInnerCoords = this.innerCoords(e, this.elem.getBoundingClientRect());
                e.stopPropagation();
            },

            end: (e) => {
                if (this.started) {
                    this.started = false;
                    e.stopPropagation();
                }
            },

            mousemove: (e) => {
                if (this.started) {

                    let diffCoords = diff(this.coords(e), this.startCoords);


                    this.startPointCoords.every(v => {
                        let dd = add(v.startCoords, diffCoords)

                    })

                    this.startPointCoords.forEach(v => {
                        v.point.updatePos(
                            add(v.startCoords, diffCoords)
                        );
                    });

                    if(null != this.movedCallback){
                        this.movedCallback(diffCoords);
                    }

                }
            },

            touchstart: (e) => {
                if (this.elem != e.target) {
                    return;
                }

                if (e.touches.length > 1) {
                    return;
                }

                this.parentRect = this.elem.getBoundingClientRect();

                this.startPointCoords = this.points.map(v => {
                    return {
                        point: v,
                        startCoords: v.getPos()
                    }
                });

                this.started = true;
                this.startCoords = this.coords(e.touches.item(0));
                this.startInnerCoords = this.innerCoords(e.touches.item(0), this.elem.getBoundingClientRect());
                e.stopPropagation();
            },

            touchmove: (e) => {
                if (this.started) {

                    let diffCoords = diff(this.coords(e.touches.item(0)), this.startCoords);


                    this.startPointCoords.every(v => {
                        let dd = add(v.startCoords, diffCoords)

                    })

                    this.startPointCoords.forEach(v => {
                        v.point.updatePos(
                            add(v.startCoords, diffCoords)
                        );
                    });

                    if(null != this.movedCallback){
                        this.movedCallback(diffCoords);
                    }

                }
            }

        };

        document.addEventListener('mousedown', this.listeners.mousedown);
        document.addEventListener('mousemove', this.listeners.mousemove);
        document.addEventListener('mouseup', this.listeners.end);


        document.addEventListener('touchstart', this.listeners.touchstart);
        document.addEventListener('touchmove', this.listeners.touchmove);
        document.addEventListener('touchend', this.listeners.end);

        // document.addEventListener('touchstart', e => {
        //     if (this.elem != e.target) {
        //         return;
        //     }
        //
        //     this.parentRect = this.elem.getBoundingClientRect();
        //
        //     this.startPointCoords = this.points.map(v => {
        //         return {
        //             point: v,
        //             startCoords: v.getPos()
        //         }
        //     });
        //
        //     this.started = true;
        //     this.startCoords = this.coords(e);
        //     this.startInnerCoords = this.innerCoords(e, this.elem.getBoundingClientRect());
        //     e.stopPropagation();
        // });

    }

    public clean(){
        document.removeEventListener('mousedown', this.listeners.mousedown);
        document.removeEventListener('mouseup', this.listeners.end);
        document.removeEventListener('mousemove', this.listeners.mousemove);

        document.removeEventListener('touchstart', this.listeners.touchstart);
        document.removeEventListener('touchmove', this.listeners.touchmove);
        document.removeEventListener('touchend', this.listeners.end)
    }

    public setMovedCallback(callback: (diff: Coords) => void){
        this.movedCallback = callback;
    }

    private coords(e: MouseEvent|Touch): Coords {
        return {
            x: e.clientX - this.parentRect.x,
            y: e.clientY - this.parentRect.y
        }
    }

    private innerCoords(e: MouseEvent|Touch, rect: DOMRect): Coords {
        return {
            x: e.clientX - rect.x,
            y: e.clientY - rect.y
        }
    }

}