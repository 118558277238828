import DcePluginInterface from "./DcePluginInterface";
import DceHTMLElement from "../../types/DceHTMLElement";

/**
 * Bazowa klasa dla
 */
export default class DcePluginBase implements DcePluginInterface{

    public initializeBody(body: HTMLElement){

    }

    public addedNodes(nodes: Node[]){

    }

    public mountedElementExtension(element: DceHTMLElement):void {

    }

}