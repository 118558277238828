import DceLiveEventAction from "./types/DceLiveEventAction";
import DceLiveEventDetails from "./types/DceLiveEventDetails";

export default function liveEvent(type: string, actions: DceLiveEventAction[], originalEvent: Event, elem: HTMLElement): CustomEvent<DceLiveEventDetails> {

    let det: DceLiveEventDetails = {
        type: type,
        actions: actions,
        originalEvent: originalEvent,
        element: elem
    }

    return new CustomEvent<DceLiveEventDetails>("dceLiveEvent",
        {
            detail: det
        }
    );
}