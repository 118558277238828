import DceComponent from "./DceComponent";
import DceHTMLElement from "../types/DceHTMLElement";
import DceEngineEventDetails from "./events/DceEngineEventDetails";
import DceEngineEvents from "./events/DceEngineEvents";
import {DceEngine} from "../index";
import DceSimpleEventDetails from "../types/DceSimpleEventDetails";

export default class DceElementExtention {

    private element: DceHTMLElement;

    private initializedComponents: Array<string>;

    private components: Map<string, DceComponent<any>>;

    private subComponents: Array<DceHTMLElement>;

    private objects: any[];

    public constructor(element: DceHTMLElement) {
        this.initializedComponents = new Array<string>();
        this.subComponents = new Array<DceHTMLElement>();

        this.components = new Map();
        this.element = element;

        this.element.addEventListener('dceEvent', ((e: CustomEvent<DceEngineEventDetails>) => {
            this.onDceEvent(e);
        }) as EventListener);

        this.element.addEventListener('dceSimple', (e:CustomEvent<DceSimpleEventDetails>) => {
            this.onDceSimpleEvent(e.detail.type);
        });

    }

    public addObject(object: any): void {
        if(null == this.objects){
            this.objects = [];
        }
        this.objects.push(object);
    }

    public dispatchSimpleEvent(eventType: string){

        this.element.dispatchEvent(new CustomEvent<DceSimpleEventDetails>("dceSimple", {
            detail: {
                type: eventType
            }
        }));

        DceEngine.getInstance().findSubComponents(this.element)
            .forEach(child => {
                child.dispatchEvent(new CustomEvent<DceSimpleEventDetails>("dceSimple", {
                    detail: {
                        type: eventType
                    }
                }));
            });
    }

    protected onDceSimpleEvent(eventType: string){
        this.components.forEach(c => {
            c.onSimpleEvent(eventType);
        });
    }

    protected onDceEvent(e: CustomEvent<DceEngineEventDetails>){

        if(e.detail.type == DceEngineEvents.SUBCOMP_INIT_EVENT){

            if(!this.subComponents.indexOf(e.detail.element)){
                this.subComponents.push(e.detail.element);
            }

            this.components.forEach(c => {
                c.onSubComponentInit(e.detail.component, e.detail.element)
            });

            return;
        }

        if(e.detail.type == DceEngineEvents.SUBCOMP_REMOVE_EVENT){

            var index = this.subComponents.indexOf(e.detail.element);

            if(index > -1){
                this.subComponents.splice(index, 1);
            }

            return;
        }

        if(this.components.has(e.detail.component)){

            switch (e.detail.type) {

                case DceEngineEvents.INIT_EVENT:
                    this.components.get(e.detail.component).onInit();
                    break;

                case DceEngineEvents.REMOVE_EVENT:
                    this.components.get(e.detail.component).onRemove();
                    break;

            }

        }

    }

    public hasComponent(name: string): boolean {
        return this.initializedComponents.indexOf(name) !== -1;
    }

    public getComponent(name: string): DceComponent<any> {
        return this.components.get(name);
    }

    public addComponent(name: string, comp: DceComponent<any>) {
        this.components.set(name, comp);
        this.initializedComponents.push(name);
    }

    public getInitializedComponents(): string[] {
        return this.initializedComponents;
    }

}
