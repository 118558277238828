import DceComponent from "./DceComponent";
import type { DceComponentConstructor } from "../types/DceComponentConstructor";

/**
 * Rejestr przechowujacy i pozwalajacy na tworzenie nowych instancji komponentów
 */
export default class DceComponentsRegister {

    private static instance: DceComponentsRegister;

    private map: Map<string, DceComponentConstructor<DceComponent<any>>> = new Map();

    private constructor() {}

    static getInstance(): DceComponentsRegister {
        if (!DceComponentsRegister.instance) {
            DceComponentsRegister.instance = new DceComponentsRegister();
        }

        return DceComponentsRegister.instance;
    }

    public add(name: string, constr: DceComponentConstructor<DceComponent<any>>) {
        console.log("register called", name, constr);
        this.map.set(name, constr);
    }

    public getConstructor(name: string): DceComponentConstructor<DceComponent<any>> {
        return this.map.get(name);
    }

    public contains(name: string) {
        return this.map.has(name);
    }
}
