import RefParent from "app/lib/dom/RefParent";

export default class PhotoFindFile {

    private elem: HTMLElement;
    private root: RefParent;

    private fileInput: HTMLInputElement;
    private buttonBrowse: HTMLButtonElement;
    private buttonPhoto: HTMLButtonElement;

    private changeEvent: (e: File) => void;

    constructor(elem: HTMLElement) {
        this.elem = elem;
        this.root = new RefParent(elem);

        this.fileInput = elem.querySelector('input[type="file"]');
        this.buttonBrowse = this.root.ref('browse', 'button');

        this.buttonPhoto = this.root.ref('photo', "button");

        if(this.fileInput.capture == undefined){
            this.buttonPhoto.style.display = 'none';
        }

        this.buttonBrowse.addEventListener('click', e => {
            this.fileInput.removeAttribute('capture');
            this.fileInput.click();
        });

        this.buttonPhoto.addEventListener('click', e => {
            this.fileInput.setAttribute('capture', 'environment');
            this.fileInput.click();
        });

        this.fileInput.addEventListener('change', e => {
            this.handleFiles(this.fileInput.files);
        });
    }

    public hide(){
        this.elem.style.display = 'none';
    }

    public show(){
        this.elem.style.display = 'block';
    }

    public setChangeEvent(callack: (e: File) => void){
        this.changeEvent = callack;
    }

    private handleFiles(files: FileList){

        if(null == this.changeEvent){
            return;
        }

        if(files.length == 0){
            setTimeout(() => {
                this.changeEvent(null);
            }, 50);
        }

        let file = files.item(0);
        setTimeout(() => {
            this.changeEvent(file);
        }, 50);

    }

}