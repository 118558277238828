import MovingPoint from "app/lib/interelcom/photofind/crop/MovingPoint";
import Blend from "app/lib/interelcom/photofind/crop/Blend";
import Coords, {max, min, rectDims} from "app/lib/interelcom/photofind/crop/support/Coords";
import MoveArea from "app/lib/interelcom/photofind/crop/MoveArea";
import RefParent from "app/lib/dom/RefParent";
import Crop from "app/lib/interelcom/photofind/crop/Crop";

export default class PhotoFindCrop {
    private elem: HTMLElement;
    private root: RefParent;

    private acceptButton: HTMLButtonElement;
    private backButton: HTMLButtonElement;
    private crop: Crop;

    private cropCallback: (canvas: HTMLCanvasElement) => void;
    private backCallback: () => void;

    constructor(elem: HTMLElement) {
        this.elem = elem;
        this.root = new RefParent(elem);

        this.crop = new Crop(this.root.div('crop-image'));
        this.acceptButton = this.root.ref('crop-accept', 'button');
        this.backButton = this.root.ref('crop-back', 'button');

        this.acceptButton.addEventListener('click', e => {
            this.doCrop();
        });

        this.backButton.addEventListener('click', e => {
            this.doBack();
        });

    }

    public hide(){
        this.crop.clean(true);
        this.elem.style.display = 'none';
    }

    public show(){
        this.elem.style.display = 'block';
    }

    public setBackCallback(callback:  () => void){
        this.backCallback = callback;
    }

    public setCropCallback(callback:  (canvas: HTMLCanvasElement) => void){
        this.cropCallback = callback;
    }

    private doCrop(){
        let canvas = document.createElement('canvas');

        this.crop.cropCanvasTo(canvas);

        if(null != this.cropCallback){
            this.cropCallback(canvas);
        }
    }

    private doBack(){
        if(null != this.backCallback){
            this.backCallback();
        }
    }

    public drawFile(file: File) {
        this.crop.drawFile(file);
    }

    public drawImage(img: HTMLImageElement) {
        this.crop.drawImage(img);
    }



}