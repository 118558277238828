import {DceAjaxAction} from "../../index";

/**
 * redirect action
 */
export default class DceAjaxRedirectAction implements DceAjaxAction {
    execute(data: any): void {
        window.location.href = data as string;
    }

    getPriority(): number {
        return 0;
    }
}