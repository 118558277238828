export default interface Coords {
    x: number,
    y: number
}

export function keepInBounds(a: number, min: number, max: number): number {

    if (a < min) {
        return min;
    }

    if (a > max) {
        return max;
    }

    return a;

}

export function distance(a: Coords, b: Coords) {
    let w = a.x - b.x;
    let h = a.y - b.y;

    return Math.hypot(w, h);
}

export function isMin(check: number, others: number[]): boolean {

    for (const other of others) {
        if (other < check) {
            return false;
        }
    }

    return true;
}

export function diff(a: Coords, b: Coords): Coords {
    return {
        x: a.x - b.x,
        y: a.y - b.y
    };
}

export function add(a: Coords, b: Coords): Coords {
    return {
        x: a.x + b.x,
        y: a.y + b.y
    };
}


export function max(a: number, b: number) {
    if (a >= b) {
        return a;
    }
    return b;
}

export function min(a: number, b: number) {
    if (a <= b) {
        return a;
    }
    return b;
}

export function rectDims(nw: Coords, se: Coords): Coords{
    return {
        x: se.x - nw.x,
        y: se.y - nw.y
    }
}