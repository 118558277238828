//TO MUSI BYĆ ABY BUNDLE ZAWIERAŁ KLASY Z components
//TS nie kompiluje nieużywanych klas??
import "dce-engine";
import "dce-live-events";
import "dce-ajax";
import "dce-splide";
import "app";

import { DceEngine } from "dce-engine";

DceEngine.start("data-dce", true);
