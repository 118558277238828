import Coords, {keepInBounds} from "app/lib/interelcom/photofind/crop/support/Coords";

export default class MovingPoint {
    private static CENTER_XY = 10;

    private parent: HTMLElement;
    private elem: HTMLDivElement;

    private rect: DOMRect;
    private parentRect: DOMRect;

    private parentWidth: number;
    private parentHeight: number;

    private started: boolean = false;
    private startCoords: Coords;
    private startInnerCoords: Coords;

    private left: number;
    private top: number;

    private minX: number = 0;
    private maxX: number = 0;
    private minY: number = 0;
    private maxY: number = 0;

    private dirX: number = 1;
    private dirY: number = 1;

    private movedPosCallback: (coords: Coords) => void;

    private listeners: {
        mousedown: (e: MouseEvent) => void,
        mousemove: (e: MouseEvent) => void,
        touchstart: (e: TouchEvent) => void,
        touchmove: (e: TouchEvent) => void,

        end: (e: MouseEvent|TouchEvent) => void,
    }


    constructor(parent: HTMLElement, elemClass: string, start: Coords, direction: Coords) {
        this.parent = parent;
        this.elem = document.createElement('div');

        this.dirX = direction.x * MovingPoint.CENTER_XY;
        this.dirY = direction.y * MovingPoint.CENTER_XY;

        this.left = start.x;
        this.top = start.y;

        let prect = this.parent.getBoundingClientRect()
        this.parentHeight = prect.height;
        this.parentWidth = prect.width;

        this.updateMinX(0);
        this.updateMaxX(this.parentWidth);

        this.updateMinY(0);
        this.updateMaxY(this.parentHeight);

        this.elem.style.position = "absolute";
        this.elem.style.left = (start.x + this.dirX) + "px";
        this.elem.style.top = (start.y + this.dirY) + "px";

        this.elem.style.width = '20px';
        this.elem.style.height = '20px';
        this.elem.classList.add(elemClass);
        this.elem.style.border = '1px solid #4581d2';
        this.elem.style.backgroundColor = "rgba(158,188,229)";

        this.parent.appendChild(this.elem);

        this.listeners = {
            mousedown: (e) => {
                if (this.elem != e.target) {
                    return;
                }
                this.rect = this.elem.getBoundingClientRect();
                this.parentRect = this.parent.getBoundingClientRect();

                this.started = true;
                this.startCoords = this.coords(e);
                this.startInnerCoords = this.innerCoords(e, this.elem.getBoundingClientRect());
                e.stopPropagation();
            },


            mousemove: (e) => {
                if (this.started) {
                    this.moveTo(this.coords(e));
                }
            },

            touchstart: (e) => {
                if (this.elem != e.target) {
                    return;
                }

                if (e.touches.length > 1) {
                    return;
                }

                this.rect = this.elem.getBoundingClientRect();
                this.parentRect = this.parent.getBoundingClientRect();

                this.started = true;
                this.startCoords = this.coords(e.touches.item(0));
                this.startInnerCoords = this.innerCoords(e.touches.item(0), this.elem.getBoundingClientRect());
                e.stopPropagation();
            },

            touchmove: (e) => {
                if (this.started) {
                    this.moveTo(this.coords(e.touches.item(0)));
                }
            },


            end: (e) => {
                if (this.started) {
                    this.started = false;
                    e.stopPropagation();
                }
            },
        };

        document.addEventListener('mousedown', this.listeners.mousedown);
        document.addEventListener('mousemove', this.listeners.mousemove);
        document.addEventListener('mouseup', this.listeners.end);

        document.addEventListener('touchstart', this.listeners.touchstart);
        document.addEventListener('touchmove', this.listeners.touchmove);
        document.addEventListener('touchend', this.listeners.end);

    }

    public clean() {
        document.removeEventListener('mousedown', this.listeners.mousedown);
        document.removeEventListener('mouseup', this.listeners.end);
        document.removeEventListener('mousemove', this.listeners.mousemove);

        document.removeEventListener('touchstart', this.listeners.touchstart);
        document.removeEventListener('touchmove', this.listeners.touchmove);
        document.removeEventListener('touchend', this.listeners.end);

        this.elem.remove();
    }

    public updateMinX(x: number) {
        this.minX = x;
    }

    public updateMaxX(x: number) {
        this.maxX = x;
    }

    public updateMinY(y: number) {
        this.minY = y;
    }

    public updateMaxY(y: number) {
        this.maxY = y;
    }

    public setMovePosCallback(callback: (coords: Coords) => void) {
        this.movedPosCallback = callback;
    }

    public updatePos(coords: Coords) {

        this.left = keepInBounds(coords.x, this.minX, this.maxX);
        this.top = keepInBounds(coords.y, this.minY, this.maxY);

        requestAnimationFrame(() => {
            this.elem.style.left = (this.left + this.dirX) + 'px';
            this.elem.style.top = (this.top + this.dirY) + 'px';
        });
    }

    public updateXPos(x: number) {

        this.left = keepInBounds(x, this.minX, this.maxX);

        requestAnimationFrame(() => {
            this.elem.style.left = (this.left + this.dirX) + 'px';
        });
    }

    public updateYPos(y: number) {
        this.top = keepInBounds(y, this.minY, this.maxY);

        requestAnimationFrame(() => {
            this.elem.style.top = (this.top + this.dirY) + 'px';
        });
    }

    private moveTo(coords: Coords) {

        //this.left = keepInBounds(100 * ((coords.x - this.startInnerCoords.x) / this.parentWidth), 0, 100);
        //this.top = keepInBounds(100 * ((coords.y - this.startInnerCoords.y) /this.parentHeight), 0, 100);

        //let left = keepInBounds(((coords.x - this.startInnerCoords.x)), this.minX, this.maxX);
        //let top = keepInBounds(((coords.y - this.startInnerCoords.y)), this.minY, this.maxY);

        let left = (coords.x - this.startInnerCoords.x);
        let top = (coords.y - this.startInnerCoords.y);


        this.left = keepInBounds(left - this.dirX, this.minX, this.maxX);
        this.top = keepInBounds(top - this.dirY, this.minY, this.maxY);
        //console.log(this.left);

        if (null != this.movedPosCallback) {
            this.movedPosCallback({x: this.left, y: this.top});
        }

        requestAnimationFrame(() => {
            this.elem.style.left = (this.left + this.dirX) + 'px';
            this.elem.style.top = (this.top + this.dirY) + 'px';
        });

        //this.elem.style.left = this.left + 'px';
        //this.elem.style.top =  this.top + 'px';

    }

    public getPos(): Coords {
        return {x: this.left, y: this.top};
    }

    private coords(e: MouseEvent | Touch): Coords {
        return {
            x: e.clientX - this.parentRect.x,
            y: e.clientY - this.parentRect.y
        }
    }


    private innerCoords(e: MouseEvent | Touch, rect: DOMRect): Coords {
        return {
            x: e.clientX - rect.x,
            y: e.clientY - rect.y
        }
    }

}