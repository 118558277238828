import DcePluginBase from "../types/DcePluginBase";
import DcePluginRegister from "../DcePluginRegister";
import {DcePluginConstructor} from "../types/DcePluginConstructor";


/**
 * Dekorator wskazujący na plugin DCE
 * @param name nazwa pluginy
 */
export default function DcePlugin(name: string){
    return (constr: DcePluginConstructor<DcePluginBase>) => {
        DcePluginRegister.getInstance().add(name, constr);
    }
}