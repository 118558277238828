import MovingPoint from "app/lib/interelcom/photofind/crop/MovingPoint";
import Blend from "app/lib/interelcom/photofind/crop/Blend";
import MoveArea from "app/lib/interelcom/photofind/crop/MoveArea";
import Coords, {max, min, rectDims} from "app/lib/interelcom/photofind/crop/support/Coords";

export default class Crop {

    private elem: HTMLElement;

    private canvas: HTMLCanvasElement;

    private imageHeight: number;
    private imageWidth: number;

    private elemHeight: number;
    private elemWidth: number;

    private aspectRatio: number = 1;

    private nwPoint: MovingPoint;
    private swPoint: MovingPoint;
    private nePoint: MovingPoint;
    private sePoint: MovingPoint;

    private topBlend: Blend;
    private bottomBlend: Blend;
    private leftBlend: Blend;
    private rightBlend: Blend;

    private minCropWidth: number;

    private movingArea: MoveArea;

    constructor(elem: HTMLElement, minCropWidth: number = 224) {
        this.elem = elem;
        this.minCropWidth = minCropWidth;
        this.elem.style.position = 'relative';
        this.elem.style.touchAction = 'none'; //wyłączy scrolowanie strony gdy przeciagamy punkty lub obszar na mobile

        window.addEventListener('resize', e => {
            if(null == this.canvas){
                return;
            }


            this.clean(false);
            this.setupMovingPoints();
        })

    }

    public cropCanvasTo(destCanvas: HTMLCanvasElement) {
        let nwPos = this.nwPoint.getPos();
        let sePos = this.sePoint.getPos();

        let rel = (this.imageWidth / this.elemWidth);

        let width = rel * (sePos.x - nwPos.x);
        let height = rel * (sePos.y - nwPos.y);

        destCanvas.width = width;
        destCanvas.height = height;

        destCanvas.getContext("2d").drawImage(
            this.canvas,
            nwPos.x * rel,
            nwPos.y * rel,
            width,
            height,  // source rect with content to crop
            0, 0, width, height
        );      // newCanvas, same size as source rect
    }

    private setupMovingPoints() {
        let rect = this.elem.getBoundingClientRect();

        this.elemWidth = rect.width;
        this.elemHeight = rect.height;

        console.log("CCCC", this.imageWidth, this.elemWidth);

        let minSelectWidth = this.minCropWidth * (this.elemWidth / this.imageWidth);

        console.log(minSelectWidth);

        let sx = 0;
        let sy = 0;
        if (this.elemWidth / this.elemHeight < this.aspectRatio) {
            //pionowy
            let offset = (this.elemHeight - this.elemWidth) / 2;
            sy = offset;
        } else {
            let offset = (this.elemWidth - this.elemHeight) / 2;
            sx = offset;
        }

        this.topBlend = new Blend(this.elem, this.elemWidth, this.elemHeight, {x: 0, y: 0}, {x: this.elemWidth, y: sy});
        this.bottomBlend = new Blend(this.elem, this.elemWidth, this.elemHeight, {x: 0, y: this.elemHeight - sy}, {x: this.elemWidth, y: this.elemHeight});
        this.leftBlend = new Blend(this.elem, this.elemWidth, this.elemHeight, {x: 0, y: 0}, {x: 0, y: this.elemHeight});
        this.rightBlend = new Blend(this.elem, this.elemWidth, this.elemHeight, {x: this.elemWidth, y: 0}, {x: this.elemWidth, y: this.elemHeight});

        this.nwPoint = new MovingPoint(this.elem, "nwpoint", {x: sx, y: sy}, {x: -1, y: -1});
        this.nePoint = new MovingPoint(this.elem, "nepoint", {x: this.elemWidth - sx, y: sy}, {x: -1, y: -1});
        this.swPoint = new MovingPoint(this.elem, "swpoint", {x: sx, y: this.elemHeight - sy}, {x: -1, y: -1});
        this.sePoint = new MovingPoint(this.elem, "sepoint", {x: this.elemWidth - sx, y: this.elemHeight - sy}, {x: -1, y: -1});

        this.updateBlends();

        this.nwPoint.setMovePosCallback(coords => {

            let sePos = this.sePoint.getPos();
            let sides = this.calcSides(coords, sePos, minSelectWidth);

            this.nwPoint.updateXPos(sePos.x - sides.x);
            this.nwPoint.updateYPos(sePos.y - sides.y);

            this.swPoint.updateXPos(this.nwPoint.getPos().x);
            this.nePoint.updateYPos(this.nwPoint.getPos().y);

            this.updateBlends();
        });

        this.swPoint.setMovePosCallback(coords => {

            let nePos = this.nePoint.getPos();
            let sides = this.calcSides(
                {x: coords.x, y: nePos.y},
                {x: nePos.x, y: coords.y},
                minSelectWidth
            );

            this.swPoint.updateXPos(nePos.x - sides.x);
            this.swPoint.updateYPos(nePos.y + sides.y);

            this.nwPoint.updateXPos(this.swPoint.getPos().x);
            this.sePoint.updateYPos(this.swPoint.getPos().y);

            this.updateBlends();
        });

        this.nePoint.setMovePosCallback(coords => {

            let sides = this.calcSides(
                {x: this.swPoint.getPos().x, y: coords.y},
                {x: coords.x, y: this.swPoint.getPos().y},
                minSelectWidth
            );

            let swPos = this.swPoint.getPos();

            this.nePoint.updateXPos(swPos.x + sides.x);
            this.nePoint.updateYPos(swPos.y - sides.y);

            this.sePoint.updateXPos(this.nePoint.getPos().x);
            this.nwPoint.updateYPos(this.nePoint.getPos().y);

            this.updateBlends();
        });

        this.sePoint.setMovePosCallback(coords => {

            let sides = this.calcSides(
                {x: this.nwPoint.getPos().x, y: this.nwPoint.getPos().y},
                {x: coords.x, y: coords.y},
                minSelectWidth
            );

            this.sePoint.updateXPos(this.nwPoint.getPos().x + sides.x);
            this.sePoint.updateYPos(this.nwPoint.getPos().y + sides.y);

            this.nePoint.updateXPos(this.sePoint.getPos().x);
            this.swPoint.updateYPos(this.sePoint.getPos().y);

            this.updateBlends();
        });

        this.movingArea = new MoveArea(this.canvas, [this.sePoint, this.nwPoint, this.swPoint, this.nePoint]);
        this.movingArea.setMovedCallback(diff => {
            this.updateBlends();
        });

    }

    private calcSides(a: Coords, b: Coords, minSelectWidth: number): Coords {

        let rect = rectDims(a, b);
        let xside = max(
            min(min((this.aspectRatio * rect.y), this.elemWidth), rect.x),
            minSelectWidth
        );
        let yside = xside / this.aspectRatio;
        return {x: xside, y: yside};
    }

    private updateBlends() {

        let nw = this.nwPoint.getPos();

        this.topBlend.updateBottom(nw.y);
        this.leftBlend.updateRight(nw.x);

        let se = this.sePoint.getPos();

        this.bottomBlend.updateTop(se.y);
        this.rightBlend.updateLeft(se.x)

        this.leftBlend.updateTop(nw.y);
        this.leftBlend.updateBottom(se.y);

        this.rightBlend.updateTop(nw.y);
        this.rightBlend.updateBottom(se.y);
    }

    public clean(removeCanvas = true) {

        if (null != this.canvas && removeCanvas) {
            this.elem.removeChild(this.canvas);
            this.canvas = null;
        }

        if (null != this.nwPoint) {
            this.nwPoint.clean();
            this.nwPoint = null;
        }

        if (null != this.swPoint) {
            this.swPoint.clean();
            this.swPoint = null;
        }

        if (null != this.nePoint) {
            this.nePoint.clean();
            this.nePoint = null;
        }

        if (null != this.sePoint) {
            this.sePoint.clean();
            this.sePoint = null;
        }

        if (null != this.movingArea) {
            this.movingArea.clean();
            this.movingArea = null;
        }

        if (null != this.bottomBlend) {
            this.bottomBlend.clean();
            this.bottomBlend = null;
        }

        if (null != this.topBlend) {
            this.topBlend.clean();
            this.topBlend = null;
        }

        if (null != this.leftBlend) {
            this.leftBlend.clean();
            this.leftBlend = null;
        }

        if (null != this.rightBlend) {
            this.rightBlend.clean();
            this.rightBlend = null;
        }

    }



    public drawFile(file: File) {
        this.clean();

        let canvas = this.appendCanvas();


        let ctx = canvas.getContext('2d');
        let reader = new FileReader();

        var img = new Image();
        img.onload = () => {
            // setup scaled dimensions
            var scaled = this.getScaledDim(img, 2000, 2000);

            console.log(scaled);
            // scale canvas to image
            ctx.canvas.width = scaled.width;
            ctx.canvas.height = scaled.height;
            // draw image
            ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);

            this.imageHeight = ctx.canvas.height;
            this.imageWidth = ctx.canvas.width;

            this.setupMovingPoints();
        }

        // this is to setup loading the image
        reader.onloadend = () => {
            if (typeof reader.result === "string") {
                img.src = reader.result;
            }
        }

        // this is to read the file
        reader.readAsDataURL(file);
    }

    public drawImage(img: HTMLImageElement) {

        this.clean();

        let canvas = this.appendCanvas();
        let ctx = canvas.getContext('2d');

        var scaled = this.getScaledDim(img, 2000, 2000);

        // scale canvas to image
        ctx.canvas.width = scaled.width;
        ctx.canvas.height = scaled.height;
        // draw image
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);

        this.imageHeight = ctx.canvas.height;
        this.imageWidth = ctx.canvas.width;

        this.setupMovingPoints();
    }

    private appendCanvas() {



        let canvas = document.createElement('canvas');
        canvas.style.display = 'block';
        canvas.style.cursor = 'move';
        canvas.style.maxWidth = '100%';


        this.elem.appendChild(canvas);
        this.canvas = canvas;

        return this.canvas;
    }


    private getScaledDim(img: HTMLImageElement, maxWidth: number, maxHeight: number) {

        console.log(img.width, img.height);

        var scaled = {
            ratio: img.width / img.height,
            width: img.width,
            height: img.height
        }


        if (scaled.width > maxWidth) {
            scaled.width = maxWidth;
            scaled.height = scaled.width / scaled.ratio;
        }

        if (scaled.height > maxHeight) {
            scaled.height = maxHeight;
            scaled.width = scaled.height * scaled.ratio;
        }


        return scaled;
    }
}