import {DceComponent, DceHTMLElement} from "dce-engine";
import PhotoFindFile from "app/lib/interelcom/photofind/PhotoFindFile";
import PhotoFindCrop from "app/lib/interelcom/photofind/PhotoFindCrop";
import MobilenetPrediction, {MobilenetConf} from "app/lib/interelcom/tfjsutils/MobilenetPrediction";
import Loading from "app/lib/interelcom/photofind/Loading";
import RefParent from "app/lib/dom/RefParent";
import PhotoFindResult from "app/lib/interelcom/photofind/PhotoFindResult";
import Dce from "dce-engine/lib/decorators/Dce";

interface PhotoFindProps {
    uploadUrl: string,
    texts: {[key:string]: string},
    showDebug: boolean
}

@Dce("PhotoFind")
export default class PhotoFind extends DceComponent<PhotoFindProps> {

    private root: RefParent;

    private mobilnet: MobilenetPrediction;

    private appElement: HTMLDivElement;

    private loading: Loading;

    private file: PhotoFindFile;

    private crop: PhotoFindCrop;

    private result: PhotoFindResult;
    private modelJsonScriptElement: HTMLScriptElement;
    private modelConf: MobilenetConf;

    constructor(elem: DceHTMLElement, props: PhotoFindProps) {
        super(elem, props);
        this.root = new RefParent(elem);

        this.appElement = this.root.div("app");
        this.loading = new Loading(this.root.div("loading"));

        this.file = new PhotoFindFile(this.root.div('file'));
        this.crop = new PhotoFindCrop(this.root.div('crop'));
        this.result = new PhotoFindResult(this.root.div('result'), this.props.texts, this.props.showDebug);

        this.modelJsonScriptElement = this.root.ref('model', 'script');

        this.modelConf = JSON.parse(this.modelJsonScriptElement.innerText);

        console.log(this.modelConf);

        this.file.setChangeEvent(file => {
            this.file.hide();
            this.crop.show();
            this.crop.drawFile(file);
        });

        this.crop.setCropCallback(async canvas => {
            this.crop.hide();
            this.result.show();

            this.result.setCanvas(canvas, this.modelConf.imageSize, this.modelConf.imageSize);
            this.result.setLoading();

            let preds = await this.mobilnet.predict(canvas);

            this.result.setPredictions(this.modelConf, preds, this.props.uploadUrl);
        });

        this.crop.setBackCallback(() => {
            this.crop.hide();
            this.file.show();
        })

        this.result.setBackCallback(() => {
            this.result.hide();
            this.file.show();
        });

        this.loadModel(
            (fraction) => {

                let downloadProgress = this.props.texts['model_download_progress'];
                if(!downloadProgress){
                    downloadProgress = 'Pobieranie: {0}%'
                }

                this.loading.setLabel(
                    downloadProgress.replace('{0}', '' + Math.round(fraction * 100))
                );
            },
            () => {
                this.loading.hide();
                this.appElement.style.display = 'block';
                let img = this.root.div('file').querySelector('img.startimg') as HTMLImageElement;
                if (img) {
                    this.crop.drawImage(img);
                }
            },
            reason => {
                this.loading.setError("Błąd modelu", reason);
            }
        )
    }


    private loadModel(progress: (fraction: number) => void, loaded: () => void, error: (reson: string) => void) {

        this.mobilnet = new MobilenetPrediction(this.modelConf);

        this.mobilnet
            .load(progress)
            .then(value => {
                loaded();
            }).catch(reason => {

            error(reason);
        })
    }


    defaultProps(): Partial<PhotoFindProps> {
        return {
            showDebug: false
        }
    }


}

