import {DceComponent, DceHTMLElement} from "dce-engine";
import {DceAjax,  DceAjaxResponse} from "dce-ajax";
import Dce from "dce-engine/lib/decorators/Dce";

interface AjaxBlockProps {
    url: string
}

@Dce("ajaxCallLink")
export default class AjaxForm extends DceComponent<AjaxBlockProps>{

    private loading: boolean = false;

    constructor(elem: DceHTMLElement, props: AjaxBlockProps) {
        super(elem, props);

        this.elem.addEventListener('click', e => {
            this.callClick(this.elem);
        });

    }


    private callClick(sender: HTMLElement){
        console.log('call on', sender);
        this.loading = true;

        let loadingElem = sender;
        if(sender.hasAttribute('data-loader')){
            loadingElem = sender.closest(sender.getAttribute('data-loader'));

            if(!loadingElem){
                loadingElem = sender;
            }
        }

        loadingElem.classList.add("loading");

        DceAjax.getInstance()
            .get(this.props.url)
            .then(response => {
                this.loading = false;
                loadingElem.classList.remove("loading");

                setTimeout(() => {
                    this.processResponse(sender, response);
                })
            })
            .catch(reason => {
                this.loading = false;
                loadingElem.classList.remove("loading");
                console.log("LOCAL REJECT", reason);
            })

    }

    private processResponse(element: HTMLElement, response: DceAjaxResponse){
        if(response.isValid() && response.hasResponse()){
            let elem = this.createElementFromHTML(response.response);
            this.elem.replaceWith(elem);
        }
    }

    private createElementFromHTML(htmlString: string):Node {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstElementChild;
    }

}