import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";
import {Modal} from "app/lib/index";

interface OpenModalProps {
    modal: string
}

@Dce("openModal")
export default class OpenModal extends DceComponent<OpenModalProps> {

    private content: HTMLElement;

    constructor(elem: DceHTMLElement, props: OpenModalProps) {
        super(elem, props);

        elem.addEventListener('click', e => {
            this.openModal();
        })

    }

    private static scrollbarWidth(): number {
        const documentWidth = document.documentElement.clientWidth;

        //console.log(documentWidth, window.innerWidth);

        return Math.abs(window.innerWidth - documentWidth);
    }


    public openModal(){
        document.querySelectorAll(this.props.modal).forEach(me => {
            let modal:Modal = (me as DceHTMLElement).ext.getComponent('modal') as any as Modal;
            modal.open();
        });
    }

}