import {DcePlugin, DcePluginBase} from "dce-engine";
import DceHTMLElement from "../../../../dce-engine/lib/types/DceHTMLElement";
import DceLiveEventDetails from "../types/DceLiveEventDetails";
import DceElementWithLiveEvents from "../DceElementWithLiveEvents";
const WIRE_EVENTS = ['click', 'submit', 'change'];

@DcePlugin("liveEvents")
export default class DceLiveEventsPlugin extends DcePluginBase{

    mountedElementExtension(element: DceHTMLElement) {
        let obj = new DceElementWithLiveEvents(element);
        element.ext.addObject(obj);
    }

}