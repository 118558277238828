import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";
import {updateURLParameter} from "app/lib/utils/updateURLParameter";
import {createDomElementFromString} from "app/lib/utils/createDomElementFromString";
import * as buffer from "buffer";

interface FiltersProps {
    url: string,
    target: string
}

interface FilterPageState {
    checked: string[],
    url: string
}

interface FilterLabelHTMLElement extends HTMLElement {
    orderNo: undefined | number;
    empty: undefined | boolean;
    selected: undefined | boolean;
    count: undefined | number;
    checkboxInputElement: undefined | HTMLInputElement;
    countElement: undefined | HTMLElement;
    text: undefined | string;
}

class FilterSelect {

    private elem: HTMLElement;
    public id: string;

    private labelsMap: Map<string, FilterLabelHTMLElement> = new Map<string, FilterLabelHTMLElement>();

    public changeCallback: () => void;

    constructor(elem: HTMLElement) {
        this.elem = elem;
        this.id = elem.getAttribute('data-id');

        console.log(this.elem)

        this.elem.parentElement.querySelector('input[data-role="search"]')
            .addEventListener('input', e => {
                this.searchInput(e);
            })

        elem.querySelectorAll('label').forEach((value, key) => {
            let fl = value as unknown as FilterLabelHTMLElement

            fl.orderNo = key;

            fl.checkboxInputElement = fl.querySelector('input');
            fl.countElement = fl.querySelector('span[data-id][data-role="count"]');

            fl.count = parseInt(value.getAttribute('data-start-count'));

            fl.empty = fl.count <= 0;

            fl.text = fl.querySelector('label .bpl-filters__f-label-nt').textContent.toLowerCase();

            this.labelsMap.set(
                value.getAttribute('for'),
                fl
            );

            fl.checkboxInputElement.addEventListener('change', e => {
                this.checkboxChangeCallback(e);
            });

        });
    }

    public searchInput(e: Event) {
        let searchTerm = (e.currentTarget as HTMLInputElement).value.trim().toLowerCase();

        if(searchTerm == '') {
            this.labelsMap.forEach((value, key) => {
                value.style.display = 'flex';
            });
        }else{
            this.labelsMap.forEach((value, key) => {

                if(value.text.indexOf(searchTerm) !== 0){
                    value.style.display = 'none';
                }else{
                    value.style.display = 'flex';
                }


            });
        }

    }

    public checkboxChangeCallback(e: Event) {
        this.changeCallback();
    }

    public getCheckedIds(): string[] {
        let checked: string[] = [];
        this.labelsMap.forEach((value, key) => {
            if (value.checkboxInputElement.checked) {
                checked.push(value.checkboxInputElement.id);
            }
        });
        return checked;
    }

    public getFilterParamPart(): string {
        let checked: string[] = [];
        this.labelsMap.forEach((value, key) => {
            if (value.checkboxInputElement.checked) {
                checked.push(value.checkboxInputElement.value);
            }
        });

        if (checked.length == 0) {
            return '';
        }

        return this.id + ":" + checked.join(",");
    }


    public uncheckAll() {
        this.labelsMap.forEach((label, key) => {
            label.checkboxInputElement.checked = false;
        });
    }

    public uncheckById(checkboxId: string) {
        this.labelsMap.forEach((value, key) => {
            if (checkboxId == (value.checkboxInputElement.id)) {
                value.checkboxInputElement.checked = false;
            }
        });
    }

    public updateFromState(state: FilterPageState) {
        this.labelsMap.forEach((value, key) => {
            if (state.checked.indexOf(value.checkboxInputElement.id) < 0) {
                value.checkboxInputElement.checked = false;
            } else {
                value.checkboxInputElement.checked = true;
            }
        });
    }


    public update(counts: { [key: string]: number }) {

        this.labelsMap.forEach((label, key) => {

            if (counts[key]) {
                label.count = counts[key];
                label.countElement.innerHTML = '(' + counts[key] + ')';
                label.empty = false;
                label.classList.remove('disabled');
            } else {
                label.count = 0;
                label.countElement.innerHTML = '(0)';
                label.classList.add('disabled');
                label.empty = true;
            }
        });

        let labels = Array.from(this.labelsMap.values());

        labels.sort((a, b) => {
            if (a.empty == b.empty) {
                return a.orderNo - b.orderNo;
            }

            if (a.empty) {
                return 1;
            }

            if (b.empty) {
                return -1;
            }

            return 0;

        }).forEach(value => {
            this.elem.appendChild(value);
        });

    }
}


@Dce("Filters")
export default class Filters extends DceComponent<FiltersProps> {

    private target: HTMLElement;

    private currentUrl = '';

    private loadState: FilterPageState;

    public selects: FilterSelect[] = [];

    constructor(elem: DceHTMLElement, props: FiltersProps) {
        super(elem, props);

        this.target = document.querySelector(this.props.target);

        this.loadState = {
            'url': '',
            'checked': []
        };

        elem.querySelectorAll('div[data-role="select"]').forEach(selectElem => {
            let filter = new FilterSelect(selectElem as HTMLElement);

            filter.changeCallback = () => {
                this.makeRequest();
            }

            this.selects.push(filter);
            this.loadState.checked.push(...filter.getCheckedIds());
        });

        window.addEventListener('popstate', e => {
            this.popStateEventCallback(e);
        });

        this.addFooterEvents();

    }

    private makeRequest(pushState: boolean = true) {

        let param: string[] = [];

        for (let e of this.selects.entries()) {
            let sparam = e[1].getFilterParamPart();
            if (sparam) {
                param.push(sparam);
            }
        }

        console.log("PARAM: " + param.join(";"));

        let url = updateURLParameter(this.props.url, 'fp', param.join(";"));

        console.log(url);

        console.log(this.target);
        this.target.classList.add('loading');

        let checkedIds = [].concat([], ...this.selects.map(value => value.getCheckedIds()));

        console.log("CHECKED IDS", checkedIds);

        DceAjax.getInstance()
            .get(url)
            .then((r) => {

                if (pushState) {
                    history.pushState({
                        'url': url,
                        'checked': checkedIds
                    }, '', url);
                }

                let counts = r.getData('counts');
                let footer = r.getData('footer');

                console.log(counts);

                for (let c in counts) {
                    console.log(c, counts[c]);
                }

                this.selects.forEach(select => {
                    select.update(counts);
                })

                this.elem.querySelector('div[data-role="footer"]').replaceWith(
                    createDomElementFromString(footer)
                );
                this.addFooterEvents();

                let targetDomElement = createDomElementFromString(r.response);
                document.querySelector(this.props.target).replaceWith(targetDomElement);
                this.target = targetDomElement as HTMLElement;

            });

    }


    public addFooterEvents() {

        let clearAllBtn = this.elem.querySelector('*[data-role="clear-filter-btn"]');

        if (null != clearAllBtn) {
            clearAllBtn.addEventListener('click', e => {
                console.log("CLEAR ALL FILTERS");
                this.selects.forEach(f => f.uncheckAll());

                this.makeRequest();
            });
        }


        this.elem.querySelectorAll('*[data-role="clear-filter-checkbox-btn"]')
            .forEach(button => {

                button.addEventListener('click', (e) => {
                    console.log("CLEAR CHECK", e.currentTarget);

                    let checkboxId = (e.currentTarget as HTMLElement).getAttribute('data-filter-checkbox');

                    console.log(checkboxId);
                    this.selects.forEach(f => f.uncheckById(checkboxId));

                    this.makeRequest();
                })

            })


        this.elem.querySelectorAll('*[data-role="clear-filter-select-btn"]')
            .forEach(button => {
                button.addEventListener('click', (e) => {
                    console.log("CLEAR CHECK", e.currentTarget);

                    let selectId = (e.currentTarget as HTMLElement).getAttribute('data-filter');

                    let makereq = false;
                    this.selects.forEach(f => {
                        if (f.id == selectId) {
                            f.uncheckAll();
                            makereq = true;
                        }
                    });

                    this.makeRequest();
                });
            })


    }


    public popStateEventCallback(e: PopStateEvent) {

        console.log("POP STATE", e.state);

        if (null == e.state) {

            console.log("EMPTY POP STATE: ", this.loadState);

            this.selects.forEach(value => {
                value.updateFromState(this.loadState);
            })

            this.makeRequest(false);
            return true;

        } else {
            //if (e.state.hasOwnProperty('url') && e.state.url != this.currentUrl) {

            this.selects.forEach(value => {
                value.updateFromState(e.state);
            })

            this.makeRequest(false);
            return true;
            //}
        }


        return true;
    }

}